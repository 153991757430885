import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import {
  ReducedFleetOrderBlueprint,
  ScheduleAvailableDays,
  ScheduleRules,
  ScheduleUsageDays,
  SkipSchedule,
} from '@fleet-customer/shared/types'

@Injectable({ providedIn: 'root' })
export class SchedulesApi {
  constructor(private http: HttpClient) {}

  findScheduleUpcoming({
    fleetAccountId,
    from,
    to,
  }: {
    fleetAccountId: string
    from: string
    to: string
  }): Observable<{ fleetOrderBlueprints: ReducedFleetOrderBlueprint[] }> {
    return this.http.get<{
      fleetOrderBlueprints: ReducedFleetOrderBlueprint[]
    }>(`/Schedules/${fleetAccountId}/upcoming`, {
      params: { from, to },
    })
  }

  getSkipDates(fleetAccountId: string): Observable<SkipSchedule> {
    return this.http.get<SkipSchedule>(`/Schedules/${fleetAccountId}/skipDates`)
  }

  updateSkipDates(
    fleetAccountId: string,
    data: SkipSchedule
  ): Observable<SkipSchedule> {
    return this.http.put<SkipSchedule>(
      `/Schedules/${fleetAccountId}/skipDates`,
      data
    )
  }

  getScheduleAllowedRules(fleetAccountId: string): Observable<ScheduleRules> {
    return this.http.get<ScheduleRules>(
      `/Schedules/${fleetAccountId}/allowedRules`
    )
  }

  getAvailableDays(fleetAccountId: string): Observable<ScheduleAvailableDays> {
    return this.http.get<ScheduleAvailableDays>(
      `/Schedules/${fleetAccountId}/availableDays`
    )
  }

  updateAvailableDays(
    fleetAccountId: string,
    data: ScheduleAvailableDays
  ): Observable<ScheduleAvailableDays> {
    return this.http.put<ScheduleAvailableDays>(
      `/Schedules/${fleetAccountId}/availableDays`,
      data
    )
  }

  getUsageDays(fleetAccountId: string): Observable<ScheduleUsageDays> {
    return this.http.get<ScheduleUsageDays>(
      `/Schedules/${fleetAccountId}/usageDays`
    )
  }

  updateUsageDays(
    fleetAccountId: string,
    data: ScheduleUsageDays
  ): Observable<ScheduleUsageDays> {
    return this.http.put<ScheduleUsageDays>(
      `/Schedules/${fleetAccountId}/usageDays`,
      data
    )
  }
}
